/* this file is transformed by vux-loader */
/* eslint-disable */
import _createForOfIteratorHelper from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import _toConsumableArray from "F:/zhaoyanning/\u9ED1\u9F99\u6C5F\u533B\u79D1\u5927\u5B66\u9644\u5C5E\u533B\u9662/mobile-v2/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.json.stringify.js";
import "core-js/modules/es.array.splice.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.array.filter.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import OrgTree from '@/components/OrgTree/OrgTree';
import Group from 'vux/src/components/group';
import Cell from 'vux/src/components/cell';
import XButton from 'vux/src/components/x-button';
import XInput from 'vux/src/components/x-input';
import Datetime from 'vux/src/components/datetime';
import Popup from 'vux/src/components/popup';
import PopupHeader from 'vux/src/components/popup-header';
import PopupPicker from 'vux/src/components/popup-picker';
import Picker from 'vux/src/components/picker';
import XSwitch from 'vux/src/components/x-switch';
export default {
  name: 'MakeOrder',
  components: {
    Group: Group,
    Cell: Cell,
    XInput: XInput,
    PopupPicker: PopupPicker,
    Datetime: Datetime,
    OrgTree: OrgTree,
    XSwitch: XSwitch,
    XButton: XButton,
    Popup: Popup,
    Picker: Picker,
    PopupHeader: PopupHeader
  },
  beforeRouteLeave: function beforeRouteLeave(to, from, next) {
    if (to.name !== 'OrgTree') {
      this.$utils.Store.removeItem('kssj');
      this.$utils.Store.removeItem('jssj');
      this.$utils.Store.removeItem('meetingTheme');
    }

    next();
  },
  data: function data() {
    return {
      roomId: this.$utils.Store.getLocalItem('roomId') ? this.$utils.Store.getLocalItem('roomId') : '',
      //会议室编号
      roomName: this.$utils.Store.getLocalItem('roomName') ? this.$utils.Store.getLocalItem('roomName') : '',
      //会议室名称
      meetingTheme: this.$utils.Store.getItem('meetingTheme') ? this.$utils.Store.getItem('meetingTheme') : '',
      //会议主题
      startDate: '',
      //开始日期
      endDate: '',
      //截止日期
      kssj: this.$utils.Store.getItem('kssj') ? this.$utils.Store.getItem('kssj') : '',
      //开始时间
      jssj: this.$utils.Store.getItem('jssj') ? this.$utils.Store.getItem('jssj') : '',
      //结束时间
      minuteList: ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'],
      //定义分钟列表
      minHour: 0,
      approver: this.$utils.Store.getItem('oldVal') ? JSON.parse(this.$utils.Store.getItem('oldVal')) : [],
      //审批人
      copyer: [],
      //固定抄送人数组
      customer: [],
      //自定义抄送人数组
      copyerChecked: [],
      //抄送人序号
      limitNum: 5,
      //最多上传图片张数
      approverString: '',
      //审批人序号--字符串
      customerString: '',
      //抄送人序号--字符串
      depSerial: this.$utils.Store.getItem('orgId'),
      //企业ID
      depName: this.$utils.Store.getItem('orgName'),
      //企业名称
      choose: this.$utils.Store.getItem('choose') ? this.$utils.Store.getItem('choose') : 0,
      //添加人员类型   1：审批人  2：抄送人  3：同行人
      bh: '',
      //会议编号
      infoChecked: [],
      //
      approverChecked: [],
      //审批人选中
      customerChecked: [],
      //自定义抄送人选中
      selectType: 0,
      //组织架构选择类型
      showFlag: false,
      //是否显示组织架构弹窗
      schedNotice: 0,
      //是否开启日程提醒  0：不提醒   1：提醒
      wxNotice: 0,
      //是否发送消息给参会人员  0：不发送  1：发送
      timeList: [{
        name: '0分钟',
        value: '0'
      }, {
        name: '5分钟',
        value: '5'
      }, {
        name: '10分钟',
        value: '10'
      }, {
        name: '15分钟',
        value: '15'
      }, {
        name: '20分钟',
        value: '20'
      }, {
        name: '25分钟',
        value: '25'
      }, {
        name: '30分钟',
        value: '30'
      }],
      //时间分钟数组
      prepareTime: [0],
      //会议准备时间
      cleanTime: [0],
      //会议清理时间
      realTime: '',
      //实际会议时间
      curTime: '',
      //当前时间
      approverFlag: false,
      approverList: [[{
        name: '',
        value: ''
      }]],
      approverSelect: [],
      approverState: false,
      isShow: false,
      //预约详情
      approveCtn: '1',
      //预约是否需要审批
      showFlagAdmin: false,
      infoCheckedAdmin: []
    };
  },
  computed: {
    isDisabled: function isDisabled() {
      return !(this.kssj !== '' && this.jssj !== '' && this.meetingTheme !== '');
    },
    // infoChecked() {
    //   if (this.selectType === 1 && this.approverChecked.length > 0) {
    //     return this.approverChecked;
    //   } else if (this.selectType === 9999 && this.customerChecked.length > 0) {
    //     return this.customerChecked;
    //   }
    // },
    showReal: function showReal() {
      //是否显示实际会议时间
      if (this.kssj !== '' && this.jssj !== '') {
        // this.realTime = this.$utils.DateUtils.subtractMinuteTime(this.kssj, parseInt(this.prepareTime)) + '至' + this.$utils.DateUtils.addMinuteTime(this.jssj, parseInt(this.cleanTime));
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    prepareTime: function prepareTime() {
      //监听入场准备时间发生变化后的实际会议时间
      this.realTime = this.$utils.DateUtils.subtractMinuteTime(this.kssj, parseInt(this.prepareTime[0])) + '至' + this.$utils.DateUtils.addMinuteTime(this.jssj, parseInt(this.cleanTime[0]));
    },
    cleanTime: function cleanTime() {
      //监听退场清理时间发生变化后的实际会议时间
      this.realTime = this.$utils.DateUtils.subtractMinuteTime(this.kssj, parseInt(this.prepareTime[0])) + '至' + this.$utils.DateUtils.addMinuteTime(this.jssj, parseInt(this.cleanTime[0]));
    },
    kssj: function kssj() {
      if (this.kssj.substring(0, 10) > this.startDate) {
        // alert(12)
        this.minHour = 8;
      }

      this.realTime = this.$utils.DateUtils.subtractMinuteTime(this.kssj, parseInt(this.prepareTime[0])) + '至' + this.$utils.DateUtils.addMinuteTime(this.jssj, parseInt(this.cleanTime[0]));
    },
    jssj: function jssj() {
      this.realTime = this.$utils.DateUtils.subtractMinuteTime(this.kssj, parseInt(this.prepareTime[0])) + '至' + this.$utils.DateUtils.addMinuteTime(this.jssj, parseInt(this.cleanTime[0]));
    }
  },
  methods: {
    /** function 获取服务器时间---今日日期
     *   wzx
     *   2019-01-11
     **/
    getSysDate: function getSysDate() {
      var _this = this;

      this.$utils.Tools.loadShow();
      this.$api.Sys.getSystemDate(this.$params.mode).then(function (response) {
        _this.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this.$code.success) {
          _this.$utils.Tools.toastShow(msg);
        } else {
          _this.startDate = data.sysDate.substring(0, 10);
          _this.curTime = data.sysDate;
        }
      }).catch(function () {
        _this.$utils.Tools.httpError();
      });
    },

    /** function 获取目的地
     *   wzx
     *   2019-01-05
     **/
    getTheme: function getTheme(val) {
      this.meetingTheme = val;
      this.$utils.Store.setItem('meetingTheme', val);
    },

    /** function 获取外出开始时间
     *   wzx
     *   2019-01-05
     **/
    changeStartTime: function changeStartTime(val) {
      this.kssj = val;
      this.$utils.Store.setItem('kssj', val);
    },

    /** function 获取外出结束时间
     *   wzx
     *   2019-01-05
     **/
    changeEndTime: function changeEndTime(val) {
      this.jssj = val;
      this.$utils.Store.setItem('jssj', val);
    },

    /** function 获取外出事由
     *   wzx
     *   2019-01-05
     **/
    getReason: function getReason(val) {
      this.reason = val;
      this.$utils.Store.setItem('reason', val);
    },
    prepareChange: function prepareChange(val) {
      this.prepareTime = val;
    },
    cleanChange: function cleanChange(val) {
      this.cleanTime = val;
    },
    onChange: function onChange() {},
    onShow: function onShow() {},
    onHide: function onHide() {},

    /**
     * 全局选择会议审批人
     */
    selectUsersAdmin: function selectUsersAdmin(val) {
      var _this2 = this;

      this.approver = val;

      if (val.length) {
        val.forEach(function (v) {
          _this2.infoCheckedAdmin.push(v.userSerial);
        });
      }
    },

    /** function 获取审批人数组
     *   wzx
     *   2018-12-26
     * */
    selectUsers: function selectUsers(val) {
      if (this.selectType === 1) {
        if (this.$utils.Store.getItem('oldVal')) {
          if (val[0].userSerial === JSON.parse(this.$utils.Store.getItem('oldVal')).userSerial) {
            this.$utils.Tools.toastShow('相邻审批人不能为同一个人');
          } else {
            var _this$approver;

            this.$utils.Store.setItem('oldVal', JSON.stringify(val[0]));

            (_this$approver = this.approver).push.apply(_this$approver, _toConsumableArray(val));
          }
        } else {
          var _this$approver2;

          this.$utils.Store.setItem('oldVal', JSON.stringify(val[0]));

          (_this$approver2 = this.approver).push.apply(_this$approver2, _toConsumableArray(val));
        }
      } else {
        if (this.customer.length > 0) {
          var _this$customer;

          this.customer = [];

          (_this$customer = this.customer).push.apply(_this$customer, _toConsumableArray(val));

          var hash = {};
          /*es6数组去重*/

          var newArr = this.customer.reduceRight(function (item, next) {
            hash[next.userSerial] ? '' : hash[next.userSerial] = true && item.push(next);
            return item;
          }, []);
          this.customer = newArr;

          var _iterator = _createForOfIteratorHelper(this.customer),
              _step;

          try {
            for (_iterator.s(); !(_step = _iterator.n()).done;) {
              var b = _step.value;
              this.customerChecked.push(b.userSerial);
            }
          } catch (err) {
            _iterator.e(err);
          } finally {
            _iterator.f();
          }
        } else {
          var _this$customer2;

          (_this$customer2 = this.customer).push.apply(_this$customer2, _toConsumableArray(val));

          var _iterator2 = _createForOfIteratorHelper(this.customer),
              _step2;

          try {
            for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
              var _b = _step2.value;
              this.customerChecked.push(_b.userSerial);
            }
          } catch (err) {
            _iterator2.e(err);
          } finally {
            _iterator2.f();
          }
        }
      }
    },
    selectApprovalUser: function selectApprovalUser() {
      var _this3 = this;

      this.$utils.Tools.loadShow();
      this.$api.Approver.selectApprovalUser({
        userSerial: this.$utils.Store.getItem('userSerial')
      }).then(function (response) {
        _this3.$utils.Tools.loadHide();

        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this3.$code.success) {
          _this3.$utils.Tools.toastShow(msg);
        } else {
          var _this3$approver;

          _this3.approver = [];

          (_this3$approver = _this3.approver).push.apply(_this3$approver, _toConsumableArray(data));
        }
      }).catch(function () {
        _this3.$utils.Tools.httpError();
      });
    },

    /** function 删除审批人
     *   wzx
     *   2018-12-28
     * */
    deleteApprover: function deleteApprover(index) {
      this.approver.splice(index, 1);
      this.$utils.Store.setItem('oldVal', JSON.stringify(this.approver));
    },

    /** function 删除自定义抄送人
     *   wzx
     *   2019-01-04
     * */
    deleteCustomer: function deleteCustomer(index) {
      this.customer.splice(index, 1);
    },

    /** function 添加审批人
     *   wzx
     *   2018-12-29
     * */
    chooseApprover: function chooseApprover() {
      //copyType----1:请假2:出差 3:外出 4:加班 5:调班6:补出勤  choose---1:审批人 2：抄送人  3：同行人
      if (this.approverList[0].length) {
        this.approverFlag = true;
      } else {
        this.showFlagAdmin = true;
      } // this.infoChecked = [];
      // this.selectType = 1;
      // this.showFlag = true;

    },

    /** function 添加抄送人
     *   wzx
     *   2018-12-29
     * */
    chooseCopyer: function chooseCopyer() {
      //copyType----1:请假 2:出差 3:外出 4:加班 5:调班6:补出勤  choose---1:审批人 2：抄送人  3：同行人
      this.infoChecked = [];
      this.selectType = 9999;
      this.showFlag = true;

      var _iterator3 = _createForOfIteratorHelper(this.customer),
          _step3;

      try {
        for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
          var b = _step3.value;
          this.infoChecked.push(b.userSerial);
        }
      } catch (err) {
        _iterator3.e(err);
      } finally {
        _iterator3.f();
      }
    },

    /** function 提交会议预约申请
     *   wzx
     *   2019-02-13
     **/
    submitClick: function submitClick() {
      var _this4 = this;

      if (this.kssj < this.curTime) {
        this.$utils.Tools.toastShow('预约时间必须大于当前时间！');
      } else if (this.kssj >= this.jssj) {
        this.$utils.Tools.toastShow('结束时间必须大于开始时间！');
      } else {
        if (this.kssj.substring(0, 10) !== this.jssj.substring(0, 10)) {
          this.$utils.Tools.toastShow('会议不可跨天预约！');
          return;
        }

        if (this.approverState && this.approver.length === 0) {
          this.$utils.Tools.toastShow('会议室管理员!');
          return;
        }

        this.approverChecked = [];
        this.imgPath = [];

        var _iterator4 = _createForOfIteratorHelper(this.approver),
            _step4;

        try {
          for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
            var i = _step4.value;
            this.approverChecked.push(i.userSerial);
          }
        } catch (err) {
          _iterator4.e(err);
        } finally {
          _iterator4.f();
        }

        if (this.approverChecked.includes(+this.$utils.Store.getItem('userSerial'))) {
          this.$utils.Tools.toastShow('不能选择自己为审批人');
          return false;
        }

        var myapprover = JSON.stringify(this.approverChecked).replace(/\[|]|"/g, '');
        this.approverString = myapprover;
        var mycopyer = JSON.stringify(this.customerChecked).replace(/\[|]|"/g, '');
        this.customerString = mycopyer;
        this.$api.Meeting.insertMeetingOrder({
          appointment: Number(this.$utils.Store.getItem('userNo')),
          roomSerial: this.roomId,
          lname: this.meetingTheme,
          kssj: this.kssj + ':00',
          jssj: this.jssj + ':00',
          jsqdsj: parseInt(this.cleanTime[0]),
          ksqdsj: parseInt(this.prepareTime[0]),
          organizer: this.roomName,
          summary: this.meetingTheme,
          qdState: 1,
          //是否运行替签到0不允许1允许
          wxNotice: this.wxNotice,
          schedNotice: this.schedNotice,
          meetApprovalsStr: myapprover,
          //审批人
          meetUsersStr: mycopyer,
          //参会人
          chaoSong: this.wxNotice
        }).then(function (response) {
          _this4.$utils.Tools.loadHide();

          var code = response.code,
              msg = response.msg,
              data = response.data;

          if (+code !== _this4.$code.success) {
            _this4.$utils.Tools.toastShow(msg);
          } else {
            _this4.isShow = true;
            _this4.approveCtn = data;
            setTimeout(function () {
              _this4.$router.go(-1);
            }, 2000); //   let state = encodeURI(data.bh);
            //   this.$router.replace({name: 'MeetingDetail', params: {state: state}});
          }
        }).catch(function () {
          _this4.$utils.Tools.httpError();
        });
      }
    },

    /** 函数function 设定当前页面
     *  wzx
     *  2019.01.09
     **/
    pushHistory: function pushHistory() {
      var state = {
        title: 'title',
        url: ''
      };
      window.history.pushState(state, state.title, state.url);
    },
    getApproveList: function getApproveList() {
      var _this5 = this;

      this.$api.Meeting.getApproveList({
        roomSerial: this.roomId
      }).then(function (response) {
        _this5.approverList = [[]];
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this5.$code.success) {
          _this5.$utils.Tools.toastShow(msg);
        } else {
          if (data && data.length > 0) {
            var _iterator5 = _createForOfIteratorHelper(data),
                _step5;

            try {
              for (_iterator5.s(); !(_step5 = _iterator5.n()).done;) {
                var item = _step5.value;

                _this5.approverList[0].push({
                  name: item.userLname,
                  value: item.userSerial
                });
              }
            } catch (err) {
              _iterator5.e(err);
            } finally {
              _iterator5.f();
            }
          }
        }
      }).catch(function () {
        _this5.$utils.Tools.httpError();
      });
    },
    confirmApprover: function confirmApprover() {
      var _this6 = this;

      var temp = this.approverList[0].filter(function (item) {
        return +item.value === +_this6.approverSelect[0];
      });
      var val = [];

      var _iterator6 = _createForOfIteratorHelper(temp),
          _step6;

      try {
        for (_iterator6.s(); !(_step6 = _iterator6.n()).done;) {
          var item = _step6.value;
          val.push({
            userName: item.name,
            userSerial: item.value
          });
        }
      } catch (err) {
        _iterator6.e(err);
      } finally {
        _iterator6.f();
      }

      if (this.$utils.Store.getItem('oldVal')) {
        var approvers = JSON.parse(this.$utils.Store.getItem('oldVal'));

        if (approvers && approvers.length > 0 && val[0].userSerial === +approvers[approvers.length - 1].userSerial) {
          this.$utils.Tools.toastShow('相邻审批人不能为同一个人');
        } else {
          var _this$approver3;

          (_this$approver3 = this.approver).push.apply(_this$approver3, val);

          this.$utils.Store.setItem('oldVal', JSON.stringify(this.approver));
        }
      } else {
        var _this$approver4;

        (_this$approver4 = this.approver).push.apply(_this$approver4, val);

        this.$utils.Store.setItem('oldVal', JSON.stringify(this.approver));
      } //或关系去重


      if (this.approver.length > 1) {
        var obj = {};
        var newArr = this.approver.reduceRight(function (item, next) {
          obj[next.userSerial] ? '' : obj[next.userSerial] = true && item.push(next);
          return item;
        }, []);
        this.approver = newArr;
      }

      this.approverFlag = false;
    },
    selectTkqRoomInfo: function selectTkqRoomInfo() {
      var _this7 = this;

      this.$api.Meeting.selectTkqRoomInfo({
        depSerial: this.roomId
      }).then(function (response) {
        var code = response.code,
            msg = response.msg,
            data = response.data;

        if (+code !== _this7.$code.success) {
          _this7.$utils.Tools.toastShow(msg);
        } else {
          if (data.sp === 1) {
            _this7.getApproveList();
          }

          _this7.approverState = data.sp === 1;
        }
      }).catch(function () {
        _this7.$utils.Tools.httpError();
      });
    }
  },
  created: function created() {},
  mounted: function mounted() {
    this.$utils.Store.removeItem('oldVal');
    this.getSysDate();
    this.selectTkqRoomInfo(); //   this.selectApprovalUser();
    // this.selectCopyUser();
  },
  destroyed: function destroyed() {
    this.$utils.Store.removeItem('oldVal');
    this.$utils.Store.removeItem('approver');
    this.$utils.Store.removeItem('copyer');
    this.$utils.Store.removeItem('customer');
  }
};